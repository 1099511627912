export const cardsPage = {
  'Main View': 'ראשי',
  'Total {{items}} Cards': 'סה״כ {{items}} רשומות',
  'The view has been saved': 'התצוגה נשמרה',
  'The view has been deleted': 'התצוגה נמחקה',
  'The view has been updated': 'התצוגה נשמרה',
  'The view has been upserted': 'התצוגה נשמרה',
  'Create View': 'יצירת תצוגה',
  'View name': 'שם התצוגה',
  'All {{name}}': 'כל {{name}}',
  Slug: 'שם מקוצר',
  'View description': 'תיאור התצוגה',
  Description: 'תיאור',
  'Private view': 'תצוגה אישית',
  save: 'שמור',
  'Save view settings': 'שמור הגדרות תצוגה',
  'Discard changes': 'אפס שינויים',
  'View settings': 'הגדרות תצוגה',
  'Delete view': 'מחיקת תצוגה',
  'Are you sure you want to delete this view?': 'האם אתה בטוח שברצונך למחוק את התצוגה?',
  'Create new view': 'יצירת תצוגה חדשה',
  'Copy Current view': 'העתקת תצוגה קיימת',
  'Show filters': 'הצג מסננים',
  'Hide filters': 'הסתר מסננים',
  'Save this state for a new list': 'שמור מצב זה לרשימה חדשה',
  'New view': 'תצוגה חדשה',
  'Update this view with this state': 'עדכן תצוגה זו עם מצב זה',
  'Tab list remove': 'הסר רשימת כרטיסים',
  'Are you sure that you want to remove "{{label}}" view?':
    'האם אתה בטוח שברצונך להסיר את התצוגה "{{label}}"?',
  'Oops!': 'אופס',
  'Nothing to show, no records found': 'אין רשומות להצגה',
  'Add new list': 'הוסף רשימה חדשה',
  'All fields': 'כל השדות',
  'Selected fields': 'שדות נבחרים',
  Location: 'מיקום',
  'After creating a new view, you could set the fields and filters easily using the table':
    'לאחר יצירת תצוגה חדשה, תוכל להגדיר את השדות והמסננים בקלות באמצעות הטבלה',
  Create: 'הוסף',
  Update: 'עדכן',
  'From scratch': 'חדש ונקי',
  Clone: 'שכפל את',
  Copy: 'העתק',
  'Move right': 'הזז ימינה',
  'Move left': 'הזז שמאלה',
  'Last activity': 'פעילות אחרונה',
  'Related value': 'ערך קשור',

  // Import
  'Drop CSV file to upload': 'גרור קובץ CSV להעלאה',
  'File is not valid, please upload only CSV file': 'קובץ לא תקין, נא להעלות קובץ CSV בלבד',
  'Drag file here or click to select file': 'גרור קובץ לכאן או לחץ לבחירת קובץ',
  Field: 'שדה',
  'Select field...': 'בחר שדה...',
  'Import {{cards}} from file': 'ייבא {{cards}} מקובץ',
  'Error parsing data as card': 'שגיאה בפענוח הנתונים',
  'Import {{count}} {{cardType}}': 'ייבא {{count}} {{cardType}}',
  'Import {{cardType}}': 'ייבא {{cardType}}',
  'Card type to relate': 'סוג כרטיס לקישור',
  'Name column is required': 'עמודת שם נדרשת',
  'Make sure that you have selected a column as "Name"': 'ודא שבחרת עמודה כ"שם"',
  'Select fields to import': 'בחר שדות לייבוא',
  'Map related fields - optional': 'מפה שדות קשורים - אופציונלי',
  '{{count}} {{cardTypes}} were successfully imported': '{{count}} {{cardTypes}} יובאו בהצלחה',
  '{{cardTypes}} were successfully imported': '{{cardTypes}} יובאו בהצלחה',

  // Export
  'Export as CSV': 'ייצא טבלה כקובץ CSV',

  // Bulk actions
  'Are you sure you want to delete {{count}} {{cardType}}?':
    'האם אתה בטוח שברצונך למחוק {{count}} {{cardType}}?',
  'Delete {{count}} selected {{cardType}}': 'מחק {{count}} {{cardType}} שנבחרו',
  Cancel: 'ביטול',
  'Select rows': 'בחר שורות',
  'Select all rows': 'בחר את כל השורות',
  '{{count}} {{cardType}} deleted': '{{count}} {{cardType}} נמחקו',
  '{{count}} {{cardType}} has been deleted': '{{count}} {{cardType}} נמחקו',

  // Create
  'Create new {{cardTypeName}}': 'צור {{cardTypeName}} חדש',

  // Kanban
  'Group by': 'קבץ לפי',
  'No {{value}}': 'אין {{value}}',
  'Sort by': 'סדר לפי',
  Values: 'ערכים',
};
