export const cardsPage = {
  'Main View': 'Fő nézet',
  'Total {{items}} Cards': 'Összesen {{items}} rekord',
  'The view has been saved': 'A nézet elmentve',
  'The view has been deleted': 'A nézet törölve',
  'The view has been updated': 'A nézet frissítve',
  'The view has been upserted': 'A nézet frissítve',
  'Create View': 'Nézet létrehozása',
  'View name': 'Nézet neve',
  'All {{name}}': 'Minden {{name}}',
  Slug: 'Rövid név',
  'View description': 'Nézet leírása',
  Description: 'Leírás',
  'Private view': 'Privát nézet',
  save: 'Mentés',
  'Save view settings': 'Nézetbeállítások mentése',
  'Discard changes': 'Változtatások elvetése',
  'View settings': 'Nézetbeállítások',
  'Delete view': 'Nézet törlése',
  'Are you sure you want to delete this view?': 'Biztosan törölni szeretné ezt a nézetet?',
  'Create new view': 'Új nézet létrehozása',
  'Copy Current view': 'Jelenlegi nézet másolása',
  'Show filters': 'Szűrők megjelenítése',
  'Hide filters': 'Szűrők elrejtése',
  'Save this state for a new list': 'Mentse ezt az állapotot egy új listához',
  'New view': 'Új nézet',
  'Update this view with this state': 'Frissítse ezt a nézetet ezzel az állapottal',
  'Tab list remove': 'Lista eltávolítása',
  'Are you sure that you want to remove "{{label}}" view?':
    'Biztosan eltávolítja a(z) "{{label}}" nézetet?',
  'Oops!': 'Hoppá!',
  'Nothing to show, no records found': 'Nincs megjeleníthető adat',
  'Add new list': 'Új lista hozzáadása',
  'All fields': 'Minden mező',
  'Selected fields': 'Kiválasztott mezők',
  Location: 'Hely',
  'After creating a new view, you could set the fields and filters easily using the table':
    'Új nézet létrehozása után könnyen beállíthatja a mezőket és szűrőket a táblázat segítségével',
  Create: 'Hozz létre',
  Update: 'Frissítés',
  'From scratch': 'Nulláról',
  Clone: 'Klónozás',
  Copy: 'Másolás',
  'Move right': 'Mozgatás jobbra',
  'Move left': 'Mozgatás balra',
  'Last activity': 'Utolsó tevékenység',
  'Related value': 'Kapcsolódó érték',

  // Import
  'Drop CSV file to upload': 'Húzza ide a CSV fájlt a feltöltéshez',
  'File is not valid, please upload only CSV file': 'Érvénytelen fájl, csak CSV fájl tölthető fel',
  'Drag file here or click to select file': 'Húzza ide a fájlt, vagy kattintson a fájl kiválasztásához',
  Field: 'Mező',
  'Select field...': 'Mező kiválasztása...',
  'Import {{cards}} from file': 'Importáljon {{cards}} fájlból',
  'Error parsing data as card': 'Hiba az adatok kártyává alakításakor',
  'Import {{count}} {{cardType}}': '{{count}} {{cardType}} importálása',
  'Import {{cardType}}': '{{cardType}} importálása',
  'Card type to relate': 'Kapcsolódó kártyatípus',
  'Name column is required': 'A név oszlop szükséges',
  'Make sure that you have selected a column as "Name"':
    'Győződjön meg róla, hogy kiválasztott egy oszlopot "Név"-ként',
  'Select fields to import': 'Válassza ki az importálni kívánt mezőket',
  'Map related fields - optional': 'Kapcsolódó mezők hozzárendelése - opcionális',
  '{{count}} {{cardTypes}} were successfully imported': '{{count}} {{cardTypes}} sikeresen importálva',
  '{{cardTypes}} were successfully imported': '{{cardTypes}} sikeresen importálva',

  // Export
  'Export as CSV': 'Exportálás CSV formátumban',

  // Bulk actions
  'Are you sure you want to delete {{count}} {{cardType}}?':
    'Biztosan törölni szeretné a(z) {{count}} {{cardType}} rekordot?',
  'Delete {{count}} selected {{cardType}}': 'Törölje a kijelölt {{count}} {{cardType}} rekordot',
  Cancel: 'Mégse',
  'Select rows': 'Sorok kiválasztása',
  'Select all rows': 'Összes sor kiválasztása',
  '{{count}} {{cardType}} deleted': '{{count}} {{cardType}} törölve',
  '{{count}} {{cardType}} has been deleted': '{{count}} {{cardType}} törölve',

  // Create
  'Create new {{cardTypeName}}': 'Új {{cardTypeName}} létrehozása',

  // Kanban
  'Group by': 'Csoportosítás',
  'No {{value}}': 'Nincs {{value}}',
  'Sort by': 'Rendezés',
  Values: 'Értékek',
};
