export const fieldsComponent = {
  // pre defined fields name
  'Email address': 'כתובת דוא"ל',
  Description: 'תיאור',
  Email: 'דוא"ל',
  'Avatar image link': 'קישור לתמונת פרופיל',
  Avatar: 'תמונה',
  'Lead status': 'סטטוס ליד',
  Name: 'שם מלא',
  'Phone number': 'מספר טלפון',
  'Select phone numbers...': 'בחר מספרי טלפון...',
  'Add a phone number': 'הוסף מספר טלפון',
  'Social security number': 'תעודת זהות',
  SSN: 'ת.ז',
  Address: 'כתובת',
  City: 'עיר',
  Age: 'גיל',
  State: 'מחוז',
  'Zip code': 'מיקוד',
  'Postal code': 'תא דואר',
  Country: 'מדינה',
  'Date of birth': 'תאריך לידה',
  'Job title': 'תפקיד',
  Website: 'אתר',
  Industry: 'תעשייה',
  'Company ID': 'ח.פ',
  Note: 'הערה',
  Fax: 'פקס',
  'Company name': 'שם חברה',
  'Number of employees': 'מספר עובדים',
  Priority: 'עדיפות',
  Language: 'שפה',
  Phone: 'טלפון',
  'Lifecycle stage': 'מצב לקוח',
  'Form validation error': 'הטופס אינו תקין',
  'Please fill all required fields and correct validation errors':
    'אנא מלא את כל השדות הנדרשים ותקן את השגיאות',
  Owner: 'אחראי',
  'Updated at': 'עודכן בתאריך',
  'Created at': 'נוצר בתאריך',
  'Updated by': 'עודכן ע"י',
  'Created by': 'נוצר ע"י',
  Source: 'מקור',
  Medium: 'מדיום',
  Campaign: 'קמפיין',
  'Year founded': 'שנת הקמה',
  'Total revenue': 'הכנסה כוללת',
  'Annual revenue': 'הכנסה שנתית',
  Comment: 'תגובה',
  Price: 'מחיר',
  SKU: 'מק"ט',
  Stock: 'מלאי',
  'Start Speech Recognition': 'התחל זיהוי קול',
  'Stop Speech Recognition': 'עצור זיהוי קול',

  'Max number of characters': 'מקסימום מספר תווים',

  // field types
  Checkbox: 'תיבת סימון',
  Date: 'תאריך',
  Color: 'צבע',
  Text: 'טקסט',
  Currency: 'מטבע',
  Accounting: 'חשבונאות',
  File: 'קובץ',
  Image: 'תמונה',
  Password: 'סיסמה',
  Radio: 'רדיו',
  Time: 'שעה',
  'Date time': 'תאריך ושעה',
  Number: 'מספר',
  URL: 'כתובת אינטרנט',
  Textarea: 'שדה טקסט',
  Range: 'טווח',
  Link: 'קישור',
  'Single select': 'בחירה מתוך רשימה',
  'Multi select': 'בחירה מרובה',
  'Add field': 'הוסף שדה',
  'Select {{cardType}}...': 'בחר {{cardType}}...',
  User: 'משתמש',
  Basic: 'בסיסי',
  Advanced: 'מתקדם',
  'Pre Defined': 'מוכנים מראש',
  Back: 'חזור',
  'Create field - {{name}}': 'הוסף שדה - {{name}}',
  'Custom Field': 'שדה מותאם אישית',
  'Save field {{label}}': `שמור שדה {{label}}`,
  'Create field': 'הוסף שדה',
  'Select card...': 'בחר כרטיס...',
  Create: 'הוסף',
  'Select user...': 'בחר משתמש...',
  'Select users...': 'בחר משתמשים...',
  'Add an email': 'הוסף דוא"ל',
  'Select emails...': "בחר כתובות דוא'ל...",
  'Select or add emails...': "בחר או הוסף כתובות דוא'ל...",
  Guests: 'אורחים',
  Yes: 'כן',
  No: 'לא',
  'Nothing found': 'לא נמצאו תוצאות',
  'Invalid email': 'כתובת דוא"ל לא תקינה',
  'No guests added': 'לא הוספו אורחים',
  awaiting: 'ממתין',
  Awaiting: 'ממתינים',
  yes: 'כן',
  no: 'לא',
  'Search...': 'חפש...',
  'Nothing here': 'כאן ריק',
  'Select cards...': 'בחר כרטיסים...',
  Geneal: 'ראשי',
  leadStatus: {
    NEW: 'חדש',
    OPEN: 'פתוח',
    IN_PROGRESS: 'בתהליך',
    OPEN_DEAL: 'עסקה פתוחה',
    UNQUALIFIED: 'לא מתאים',
    ATTEMPTED_TO_CONTACT: 'ניסיון ליצירת קשר',
    CONNECTED: 'מחובר',
    BAD_TIMING: 'לא זמן טוב',
    PILOT: 'פילוט',
    PAYING_CUSTOMER: 'לקוח משלם',
  },
  priority: {
    LOW: 'נמוך',
    MEDIUM: 'בינוני',
    HIGH: 'גבוה',
    URGENT: 'דחוף',
  },
  lifecycleStage: {
    SUBSCRIBER: 'מנוי',
    LEAD: 'ליד',
    MARKETING_QUALIFIED_LEAD: 'ליד מסונן שיווקית',
    SALES_QUALIFIED_LEAD: 'ליד מסונן מכירות',
    OPPORTUNITY: 'הזדמנות',
    CUSTOMER: 'לקוח',
    EVANGELIST: 'אבנגליסט',
    OTHER: 'אחר',
  },
};
