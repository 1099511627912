export const common = {
  Cancel: 'ביטול',
  Confirm: 'אשר',
  'Organization info': 'מידע על הארגון',
  'Account Name': 'שם החשבון',
  'Organization name': 'שם הארגון',
  Logo: 'לוגו',
  'Business Number': 'מספר עסק',
  Create: 'צור',
  Save: 'שמור',
  Submit: 'שלח',
  Language: 'שפה',
  Slug: 'שם מקוצר',
  'Plural Title': 'כותרת רבים',
  Order: 'סדר',
  'Sort order': 'מיקום ברשימה',
  Title: 'כותרת',
  Automation: 'אוטומציה',
  Status: 'סטטוס',
  Meetings: 'פגישות',
  Tasks: 'משימות',
  Fields: 'שדות',
  Description: 'תיאור',
  Communications: 'תקשורת',
  Company: 'חברה',
  Comments: 'הערות',
  Upload: 'העלה',
  Expires: 'פג תוקף התוכנית',
  Deals: 'עסקאות',
  'Card Types': 'סוגי כרטיסים',
  Error: 'שגיאה',
  Update: 'עדכן',
  'Created at': 'נוצר בתאריך',
  Plan: 'תוכנית',
  Dashboard: 'לוח בקרה',
  Settings: 'הגדרות',
  'Finance documents': 'מסמכים חשבונאיים',
  Cards: 'כרטיסיות',
  Projects: 'פרויקטים',
  Companies: 'חברות',
  Loading: 'טוען',
  'API Name': 'שם API',
  Contacts: 'אנשי קשר',
  Rooms: 'חדרים',
  Record: 'רשומה',
  Card: 'כרטיס',
  Reminders: 'תזכורות',
  Snooze: 'דחה',
  Dismiss: 'התעלם',
  Continue: 'המשך',
  Agenda: 'לוח זמנים',
  Upcoming: 'בקרוב',
  'Create {{search}}': 'צור {{search}}',
  'Select, search or create...': 'בחר, חפש או צור...',
  'Nothing found': 'לא נמצאו תוצאות',
  'Loading...': 'טוען...',
  'More details': 'פרטים נוספים',
  'Time Zone': 'אזור זמן',
  'Form is not valid, please check the fields': 'הטופס אינו תקין, אנא בדוק את השדות',
  'Nothing found matching the search word "{{search}}"': 'לא נמצא ערך התואם את החיפוש "{{search}}"',
  'Settings updated successfully': 'ההגדרות עודכנו בהצלחה',
  Phone: 'טלפון',
  Email: 'אימייל',
  Address: 'כתובת',

  // Fields
  'No results found': 'לא נמצאו תוצאות',
  'Select...': 'בחר...',
  'Search...': 'חפש...',
  'Type to search...': 'הקלידו כדי לחפש...',
  Edit: 'עריכה',
  Delete: 'מחיקה',
  Remove: 'הסרה',

  'Open in new tab': 'פתח בטאב חדש',
  'Invalid URL': 'כתובת אינה תקינה',
  Currency: 'מטבע',
  edit: 'עריכה',
  delete: 'מחיקה',
  newActivity: 'צור פעילות',
  more: 'עוד',
  view: 'צפה',

  Shrink: 'צמצם',
  Expand: 'הרחב',

  // Currencies
  'Israeli Shekel': 'ש"ח',
  'US Dollar': 'דולר',
  'British Pound': 'לירה',
  Euro: 'יורו',
  'Japanese Yen': 'ין',
  'Canadian Dollar': 'דולר קנדי',
  'Polish Zloty': 'זלוטי פולני',
  'Hungarian Forint': 'פורינט הונגרי',

  // Languages
  en: 'אנגלית',
  he: 'עברית',
  hu: 'הונגרית',

  // Support ticket
  'Create new support ticket': 'פתח פניה חדשה לתמיכה',
  'Something went wrong': 'משהו השתבש',
  'Failed to send message, please try again later': 'נכשל בשליחת ההודעה, אנא נסה שוב מאוחר יותר',
  'We are on it!': 'אנחנו עובדים על זה!',
  'We got your message and will get back to you soon': 'קיבלנו את ההודעה שלך ונחזור אליך בקרוב',
  'Title your ticket': 'כותרת לפניה שלך',
  'Please describe your issue': 'אנא תאר את הבעיה שלך',
  'Upgrade Plan': 'שדרגת תוכנית',
  'I would like to upgrade my plan to the next tier. Please contact me to discuss the details':
    'אני רוצה לשדרג את התוכנית שלי. אנא צרו איתי קשר',
  Send: 'שלח',
  go: 'עבור',
  Search: 'חפש',
};
