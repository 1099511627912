import { useUser } from '@clerk/nextjs';
import { Avatar, Button, Group, Menu, Stack, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { appColors } from '~/ui/app-colors';
import { useOrganizations } from '~/ui/hooks/organizations.hook';
import { useAppRouter } from '../../../ui/hooks/app.router.hook';

export const OrgInfo: React.FC<{ multiple: boolean }> = ({ multiple = false }) => {
  const { user, isLoaded: isUserLoaded } = useUser();
  const { organization, isLoading: isOrganizationLoading } = useOrganizations();

  if (!isUserLoaded || isOrganizationLoading) return null;

  return (
    <Group gap={2}>
      {organization?.hasImage && (
        <Avatar
          size="md"
          src={organization?.imageUrl}
          name={organization?.name}
          radius="md"
          alt={organization?.name}
        />
      )}
      <Stack gap={0} align="start">
        <Text size="sm" fw={500}>
          {organization?.name}
        </Text>

        <Text c="dimmed" size="xs">
          {user?.fullName ?? user?.primaryEmailAddress?.emailAddress}
        </Text>
      </Stack>

      {multiple && <IconChevronDown size={14} stroke={1.5} />}
    </Group>
  );
};

export const OrganizationSelector: React.FC = () => {
  const { isMarketingPage } = useAppRouter();
  const { organization, userMemberships, isLoading, selectOrganization } = useOrganizations();

  if (isLoading || userMemberships.count === 0) return null;

  if (isMarketingPage) return null;

  if (userMemberships?.count === 1) {
    return <OrgInfo multiple={false} />;
  }

  return (
    <Menu width={200} shadow="md">
      <Group gap={0}>
        <Menu.Target>
          <Button variant="transparent" px="xs">
            <OrgInfo multiple={true} />
          </Button>
        </Menu.Target>
      </Group>
      <Menu.Dropdown>
        {userMemberships?.data?.map(({ organization: { id, name, imageUrl } }) => (
          <Menu.Item
            leftSection={imageUrl ? <Avatar size="sm" radius="md" src={imageUrl} alt={name} /> : null}
            key={id}
            color={id === organization?.id ? appColors.themeColor : undefined}
            onClick={() => selectOrganization(id)}
          >
            {name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
