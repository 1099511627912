export const common = {
  Cancel: 'Mégse',
  Confirm: 'Megerősítés',
  'Organization info': 'Szervezeti információk',
  'Account Name': 'Fiók neve',
  'Organization name': 'Szervezet neve',
  Logo: 'Logó',
  'Business Number': 'Üzleti azonosító',
  Create: 'Létrehozás',
  Save: 'Mentés',
  Submit: 'Küldés',
  Language: 'Nyelv',
  Slug: 'Rövid név',
  'Plural Title': 'Többes cím',
  Order: 'Sorrend',
  'Sort order': 'Rendezési sorrend',
  Title: 'Cím',
  Automation: 'Automatizálás',
  Status: 'Állapot',
  Meetings: 'Találkozók',
  Tasks: 'Feladatok',
  Fields: 'Mezők',
  Description: 'Leírás',
  Communications: 'Kommunikáció',
  Company: 'Cég',
  Comments: 'Megjegyzések',
  Upload: 'Feltöltés',
  Expires: 'Lejárat',
  Deals: 'Ügyletek',
  'Card Types': 'Kártyatípusok',
  Error: 'Hiba',
  Update: 'Frissítés',
  'Created at': 'Létrehozva',
  Plan: 'Terv',
  Dashboard: 'Irányítópult',
  Settings: 'Beállítások',
  'Finance documents': 'Pénzügyi dokumentumok',
  Cards: 'Kártyák',
  Projects: 'Projektek',
  Companies: 'Cégek',
  Loading: 'Betöltés',
  'API Name': 'API név',
  Contacts: 'Kapcsolatok',
  Rooms: 'Szobák',
  Record: 'Bejegyzés',
  Card: 'Kártya',
  Reminders: 'Emlékeztetők',
  Snooze: 'Szundi',
  Dismiss: 'Elutasítás',
  Continue: 'Folytatás',
  Agenda: 'Napirend',
  Upcoming: 'Közelgő',
  'Create {{search}}': 'Hozz létre {{search}}',
  'Select, search or create...': 'Válassz, keress, vagy hozz létre...',
  'Nothing found': 'Nem található semmi',
  'Loading...': 'Betöltés...',
  'More details': 'További részletek',
  'Time Zone': 'Időzóna',
  'Form is not valid, please check the fields': 'Az űrlap nem érvényes, kérjük, ellenőrizze a mezőket',
  'Nothing found matching the search word "{{search}}"':
    'Nincs találat a(z) "{{search}}" keresési szóra',
  'Settings updated successfully': 'Beállítások sikeresen frissítve',
  Phone: 'Telefon',
  Email: 'E-mail',
  Address: 'Cím',

  // Fields
  'No results found': 'Nincs találat',
  'Select...': 'Válassz...',
  'Search...': 'Keresés...',
  'Type to search...': 'Írj be kereséshez...',
  Edit: 'Szerkesztés',
  Delete: 'Törlés',
  Remove: 'Eltávolítás',

  'Open in new tab': 'Megnyitás új lapon',
  'Invalid URL': 'Érvénytelen URL',
  Currency: 'Pénznem',
  edit: 'Szerkesztés',
  delete: 'Törlés',
  newActivity: 'Új tevékenység',
  more: 'Több',
  view: 'Nézet',

  Shrink: 'Összehúzás',
  Expand: 'Kibontás',

  // Currencies
  'Israeli Shekel': 'Izraeli sékel',
  'US Dollar': 'Amerikai dollár',
  'British Pound': 'Brit font',
  Euro: 'Euró',
  'Japanese Yen': 'Japán jen',
  'Canadian Dollar': 'Kanadai dollár',
  'Polish Zloty': 'Lengyel zloty',
  'Hungarian Forint': 'Magyar forint',

  // Languages
  en: 'Angol',
  he: 'Héber',
  hu: 'Magyar',

  // Support ticket
  'Create new support ticket': 'Új támogatási jegy létrehozása',
  'Something went wrong': 'Valami hiba történt',
  'Failed to send message, please try again later':
    'Nem sikerült elküldeni az üzenetet, próbáld újra később',
  'We are on it!': 'Dolgozunk rajta!',
  'We got your message and will get back to you soon': 'Megkaptuk az üzeneted, hamarosan válaszolunk',
  'Title your ticket': 'Add meg a jegyed címét',
  'Please describe your issue': 'Kérlek, írd le a problémádat',
  'Upgrade Plan': 'Terv frissítése',
  'I would like to upgrade my plan to the next tier. Please contact me to discuss the details':
    'Szeretném frissíteni a tervemet a következő szintre. Kérlek, vedd fel velem a kapcsolatot a részletekért',
  Send: 'Küldés',
  go: 'Menj',
  Search: 'Keresés',
};
